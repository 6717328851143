<template>
  <div class="layout-footer">
    <span class="font-light"
      >© by catch and code GmbH and Massif Webdesign – v{{ version }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  setup() {
    const version = process.env.VUE_APP_VERSION
    return {
      version
    }
  }
}
</script>
