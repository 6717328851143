import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import useAuthUser from '@/use/useAuthUser'
import useStore from '@/store/index.js'

const { setIsLoading } = useStore()

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home',
      requiresAuth: false
    }
  },
  {
    path: '/clients',
    name: 'ViewClients',
    component: () => import('@/views/ViewClients.vue'),
    meta: {
      title: 'Clients',
      requiresAuth: true
    }
  },
  {
    path: '/clients/:id',
    name: 'ViewClient',
    component: () => import('@/views/ViewClient.vue'),
    meta: {
      title: 'Client',
      requiresAuth: true
    }
  },
  {
    path: '/events',
    name: 'ViewEvents',
    component: () => import('@/views/ViewEvents.vue'),
    meta: {
      title: 'Events',
      requiresAuth: true
    }
  },
  {
    path: '/events/:id',
    name: 'ViewEvent',
    component: () => import('@/views/ViewEvent.vue'),
    meta: {
      title: 'Event',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Login',
      requiresAuth: false
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      title: 'Login',
      requiresAuth: false
    },
    beforeEnter: async () => {
      const { logout } = useAuthUser()
      await logout()
      return { name: 'Home' }
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
    meta: {
      title: 'Register',
      requiresAuth: false
    }
  },
  {
    path: '/email-confirmation',
    name: 'EmailConfirmation',
    component: () => import('@/views/EmailConfirmation.vue'),
    meta: {
      title: 'Email confirmation',
      requiresAuth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      title: 'Forgot Password',
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

const scrollToTop = () => {
  window.scrollTo(0, 0)
}
const setDocumentTitle = (to) => {
  document.title = `${to.meta.title} | guides-choice`
}

router.beforeEach((to, from, next) => {
  setDocumentTitle(to)
  scrollToTop()
  setIsLoading(true)
  next()
})

router.beforeResolve(() => {
  setIsLoading(false)
})

router.beforeEach((to) => {
  const { isLoggedIn } = useAuthUser()
  if (
    !isLoggedIn() &&
    to.meta.requiresAuth &&
    !Object.keys(to.query).includes('fromEmail')
  ) {
    return { name: 'Home' }
  }
})

export default router
