import { createClient } from '@supabase/supabase-js'
import useStore from '@/store/index.js'

const supabaseUrl = 'https://oquggabohdhtlwhtqctl.supabase.co'
const supabaseAnonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYzNjAzNzAzNCwiZXhwIjoxOTUxNjEzMDM0fQ.NaFjsrRMeJ_PMu19mg6fDAwWfENBu_cWLrVfls3Jnfs'

const supabase = createClient(supabaseUrl, supabaseAnonKey)

const { setUser } = useStore()

// setup auth state listener
supabase.auth.onAuthStateChange((event, session) => {
  // the "event" is a string indicating what trigger the state change (ie. SIGN_IN, SIGN_OUT, etc)
  if (event === 'SIGNED_OUT' && session === null) {
    location.reload()
  }
  setUser(session?.user)
})

export default function useSupabase() {
  return { supabase }
}
