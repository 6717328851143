<template>
  <div :class="containerClass" @click="onWrapperClick">
    <Toast />
    <ProgressBar v-show="isLoading" mode="indeterminate" />
    <AppTopBar :items="topMenu" @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div
        v-if="mobileMenuActive"
        class="layout-mask p-component-overlay"
      ></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import AppFooter from './AppFooter.vue'
import { useRouter } from 'vue-router'
import useStore from '@/store/index.js'
import { computed } from 'vue'

export default {
  name: 'App',
  components: {
    AppTopBar,
    AppMenu,
    AppFooter
  },
  setup() {
    console.info('VUE_APP_VERSION: ', process.env.VUE_APP_VERSION)
    const { user, isLoading } = useStore()
    const router = useRouter()
    const routes = router.getRoutes()
    const getRoutePath = (name) =>
      routes.find((element) => element.name === name)

    const topMenu = computed(() => [
      {
        label: 'Register',
        icon: 'pi pi-user-plus',
        to: getRoutePath('Register'),
        visible: () => !user.value
      },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        to: getRoutePath('Logout'),
        visible: () => user.value
      }
    ])

    const menu = computed(() => [
      {
        items: [
          {
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            to: getRoutePath('Home')
          },
          {
            label: 'View Clients',
            icon: 'pi pi-fw pi-users',
            to: getRoutePath('ViewClients'),
            visible: () => user.value
          },
          {
            label: 'View Events',
            icon: 'pi pi-fw pi-sun',
            to: getRoutePath('ViewEvents'),
            visible: () => user.value
          }
        ]
      }
    ])

    return {
      isLoading,
      user,
      topMenu,
      menu
    }
  },
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false
    }
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active':
            this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
          'layout-theme-light': this.$appState.theme.startsWith('saga')
        }
      ]
    },
    logo() {
      return this.$appState.darkTheme
        ? 'images/logo-white.svg'
        : 'images/logo.svg'
    }
  },
  watch: {
    $route() {
      this.menuActive = false
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden')
    else this.removeClass(document.body, 'body-overflow-hidden')
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }

      this.menuClick = false
    },
    onMenuToggle() {
      this.menuClick = true

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true
          }

          this.overlayMenuActive = !this.overlayMenuActive
          this.mobileMenuActive = false
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }

      event.preventDefault()
    },
    onSidebarClick() {
      this.menuClick = true
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className)
      else element.className += ' ' + className
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className)
      else
        element.className = element.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi'
          ),
          ' '
        )
    },
    isDesktop() {
      return window.innerWidth >= 992
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive
      }

      return true
    }
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
