<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()" />
    </router-link>
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true
      }"
      class="p-link layout-topbar-menu-button layout-topbar-button"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <Dropdown v-model="locale" :options="availableLocales" />
      </li>
      <template v-for="item of items" :key="item.name">
        <li v-if="isVisible(item)">
          <router-link class="p-link layout-topbar-button" :to="item.to"
            ><i :class="item.icon"></i><span>{{ item.name }}</span></router-link
          >
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { isMenuItemVisible as isVisible } from '@/use/useMenu.js'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  emits: ['menu-toggle', 'topbar-menu-toggle'],
  setup() {
    const { t, locale, availableLocales } = useI18n({ useScope: 'global' })
    return {
      isVisible,
      t,
      locale,
      availableLocales
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme
    }
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event)
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? 'images/logo-white.svg'
        : 'images/logo-dark.svg'
    }
  }
}
</script>
