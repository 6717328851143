<template>
  <form @submit.prevent="handleLogin">
    <h5>{{ t('login.title') }}</h5>
    <transition-group name="p-message" tag="div">
      <Message
        v-for="msg of message"
        :key="msg.id"
        :closable="false"
        :sticky="false"
        :life="3000"
        :severity="msg.severity"
        >{{ msg.content }}</Message
      >
    </transition-group>

    <div class="field grid">
      <label for="email" class="col-12 mb-2 md:col-2 md:mb-0">{{
        t('login.email')
      }}</label>
      <div class="col-12 md:col-10">
        <InputText id="email" v-model="form.email" type="email" required />
      </div>
    </div>
    <div class="field grid">
      <label for="password" class="col-12 mb-2 md:col-2 md:mb-0">{{
        t('login.password')
      }}</label>
      <div class="col-12 md:col-10">
        <InputText
          id="password"
          v-model="form.password"
          type="password"
          required
        />
      </div>
    </div>
    <Button :label="t('login.login')" type="submit"></Button>
    <p class="tw-mt-3">
      <small
        ><router-link
          class="text-sm mt-6 text-center"
          :to="{ name: 'Register' }"
          >{{ t('login.noAccount') }}
          <span class="text-at-light-green">{{
            t('login.register')
          }}</span></router-link
        ><br />
        <router-link
          class="text-sm mt-6 text-center"
          :to="{ name: 'ForgotPassword' }"
          >{{ t('login.forgotPassword') }}</router-link
        ></small
      >
    </p>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import useAuthUser from '@/use/useAuthUser'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { login, loginWithSocialProvider } = useAuthUser()

const router = useRouter()
const form = ref({
  email: '',
  password: ''
})

const message = ref([])
let count = 1

const handleLogin = async (provider) => {
  try {
    typeof provider === 'string'
      ? await loginWithSocialProvider(provider)
      : await login(form.value)
    addMessage('success', t('login.toast.success'))
    setTimeout(() => {
      router.push({ name: 'ViewClients' })
    }, 1500)
  } catch (error) {
    if (error.status === 400) {
      addMessage('error', t('login.toast.error'))
    } else {
      addMessage('error', error.message)
    }
  }
}

const addMessage = (type, content) => {
  message.value = [
    {
      severity: type,
      content: content,
      id: count++
    }
  ]
}
</script>
