<template>
  <div class="grid home" data-testid="home">
    <div class="col-12 md:col-6">
      <div class="card p-fluid">
        <h1 class="text-4xl mb-5">{{ t('home.title') }}</h1>
        <Login v-if="!user" />
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/views/Login.vue'
import { useI18n } from 'vue-i18n'
import useStore from '@/store'

export default {
  name: 'Home',
  components: {
    Login
  },
  setup() {
    const { t } = useI18n()
    const { user } = useStore()

    return {
      t,
      user
    }
  }
}
</script>
