import { reactive, computed } from 'vue'

const state = reactive({
  user: null,
  isLoading: false
})

export default function useStore() {
  const user = computed(() => state.user)
  const setUser = (payload) => (state.user = payload ? payload : null)

  const isLoading = computed(() => state.isLoading)
  const setIsLoading = (newState) => (state.isLoading = newState)

  return {
    user,
    setUser,
    isLoading,
    setIsLoading
  }
}
