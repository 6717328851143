import { createApp, reactive } from 'vue'
import { createI18n } from 'vue-i18n'
import App from '@/App.vue'
import router from '@/router'

import './index.css'

// PrimeVue
import PrimeVue from 'primevue/config'
import 'primevue/resources/primevue.min.css' //core css
import 'primeflex/primeflex.css' //utilities css
import 'primeicons/primeicons.css' //icons css
import '@/assets/styles/layout.scss' //layout scss

// PrimeVue components
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import Menu from 'primevue/menu'
import Message from 'primevue/message'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import ProgressBar from 'primevue/progressbar'
import RadioButton from 'primevue/radiobutton'
import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'
import Tag from 'primevue/tag'
import Toast from 'primevue/toast'
import Toolbar from 'primevue/toolbar'
import ToastService from 'primevue/toastservice'
import Textarea from 'primevue/textarea'
import MultiSelect from 'primevue/multiselect'

// Translations
import de from '@/translations/de.json'
import en from '@/translations/en.json'

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: 'de',
  fallbackLocale: 'en',
  messages: {
    de,
    en
  }
})

const app = createApp(App)

app.config.globalProperties.$appState = reactive({
  theme: 'lara-light-indigo',
  darkTheme: false
})

app.use(i18n)
app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' })
app.use(router)
app.use(ToastService)

app.directive('badge', BadgeDirective)
app.directive('ripple', Ripple)
app.directive('styleclass', StyleClass)

app.component('Badge', Badge)
app.component('Button', Button)
app.component('Calendar', Calendar)
app.component('Column', Column)
app.component('DataTable', DataTable)
app.component('DataView', DataView)
app.component('Dialog', Dialog)
app.component('Dropdown', Dropdown)
app.component('InputNumber', InputNumber)
app.component('InputText', InputText)
app.component('Menu', Menu)
app.component('Message', Message)
app.component('MultiSelect', MultiSelect)
app.component('OverlayPanel', OverlayPanel)
app.component('Paginator', Paginator)
app.component('ProgressBar', ProgressBar)
app.component('RadioButton', RadioButton)
app.component('Tag', Tag)
app.component('Textarea', Textarea)
app.component('Toolbar', Toolbar)
app.component('Toast', Toast)

app.mount('#app')
